.logsContainer {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000a;
    opacity: 1;
    padding: 40px;
  
    .searchContainer {
      display: flex;
      align-items: center;
      // margin-bottom: 30px;
  
      p {
        margin: 0 20px;
      }
      input {
        padding: 4px 10px;
        border-color: #339dff;
        font-size: 14px;
        box-shadow: none;
        width: 300px;
        &:hover {
          border-color: #339dff;
        }
        &:focus {
          box-shadow: none !important;
        }
      }
      i::before {
        color: #339dff;
        font-weight: bolder;
      }
    }
  
    .searchBtn {
      border: none;
      height: 40px;
      font-size: 1.00rem;
      margin: 0 10px;
      background: #339dff;
      color: white;
      font-weight: lighter;
      border-color: #339dff;
      box-shadow: none;
      outline: none;
      border-radius: 5px;
      width: 100px;
    }
  
    .nameCol {
      color: #339dff;
      text-decoration: none;
      &:hover {
        color: #339dff;
        text-decoration: none;
      }
    }
  }
  
  :global {
    .p-sortable-column-icon {
      &::before {
        color: #339dff;
        font-weight: 700;
        margin: 0 5px;
      }
    }
  
    
    
  }
  .excel-button{
    width: 150px;
    height: 40px;
    padding: 10px 20px;
    font-weight: lighter;
    font-size: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #0056b3;
    }
      
  }