.AdminsContainer {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 40px;

  .searchContainer {
    display: flex;
    align-items: center;
    // margin-bottom: 30px;

    p {
      margin: 0 20px;
    }
    input {
      padding: 4px 10px;
      border-color: #339dff;
      font-size: 14px;
      box-shadow: none;
      width: 300px;
      &:hover {
        border-color: #339dff;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
    i::before {
      color: #339dff;
      font-weight: bolder;
    }
  }

  .searchBtn {
    border: none;
    height: 40px;
    font-size: 1.00rem;
    margin: 0 10px;
    background: #339dff;
    color: white;
    font-weight: lighter;
    border-color: #339dff;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    width: 100px;
  }

  .nameCol {
    color: #339dff;
    text-decoration: none;
    &:hover {
      color: #339dff;
      text-decoration: none;
    }
  }

  .delete-button {
    width: 80px; /* Set a fixed width */
    height: 40px;
    background-color: #bb2709;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .delete-button:hover {
    background-color: #cb4328;
  }

  .new-input {
    padding: 4px 10px;
    border-color: #71a5d6;
    font-size: 14px;
    box-shadow: none;
    width: 300px;
    margin: 10px;
    border-radius: 5px;

    &:hover {
      border-color: #339dff;
    }
    &:focus {
      box-shadow: none !important;
    }
  }

  .new-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .new-button:hover {
    background-color: #0056b3;
  }

  .cancel-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .cancel-button:hover {
    background-color: #999;
  }
}

:global {
  .p-sortable-column-icon {
    &::before {
      color: #339dff;
      font-weight: 700;
      margin: 0 5px;
    }
  }

  
  
}
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
}


/* Add this CSS to your styles file (e.g., Admins.styles.scss) */
.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above everything else */
}

.confirmation-dialog {
  background: #fff; /* White background for the dialog */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

