.add-admin-form {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 5px;

  h3 {
    color: #366591; /* Set the desired text color */
  font-weight: 300; /* Set the desired font weight */
  margin: 10px 0; /* Add top and bottom margin for spacing */
  font-size: 1.5rem; /* You can keep the font size if needed */
  font-family: 'Source Sans Pro'
  }

  input[type="text"],
  input[type="password"] {
    width: 50%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;

    &:hover {
      border-color: #339dff;
    }

    &:focus {
      border-color: #339dff;
    }
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input[type="checkbox"] {
      margin-right: 10px;
    }
  }

  button.new-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 10px;

    &:hover {
      background-color: #0056b3;
    }
  }

  button.cancel-button {
    background-color: #655f5f;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-left: 10px;

    &:hover {
      background-color: #999;
    }
  }

  // Custom style for the checkbox
  input[type="checkbox"] {
    position: static;
    opacity: 1;
  
    & + label {
      display: inline-block;
      position: relative;
      padding-left: 30px; // Space for the custom checkbox
      cursor: pointer;
      user-select: none;
  
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px; // Width of the custom checkbox
        height: 20px; // Height of the custom checkbox
        border: 2px solid #339dff; // Border color of the checkbox
        background-color: white;
        border-radius: 5px; // Rounded corners
      }
  
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px; // Position of the checkmark
        top: 2px;
        width: 5px; // Width of the checkmark
        height: 10px; // Height of the checkmark
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); // Rotate the checkmark
      }
    }
  
    &:checked + label::before {
      background-color: #339dff; // Background color when checked
      border: 2px solid #339dff; // Border color when checked
    }
  }
  
}
