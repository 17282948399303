.invoicesContainer {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 40px;

  .searchContainer {
    display: flex;
    align-items: center;
    // margin-bottom: 30px;
    :global {
      .p-sortable-column-icon {
        &::before {
          color: #339dff;
          font-weight: 700;
          margin: 0 5px;
        }
      }
    
      


    p {
      margin: 0 20px;
    }
    input {
      padding: 4px 10px;
      border-color: #339dff;
      box-shadow: none;
      font-size: 14px;
      &:hover {
        border-color: #339dff;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
    i::before {
      color: #339dff;
      font-weight: bolder;
    }
  }

  .searchBtn {
    border: none;
    height: 40px;
    font-size: 1.00rem;
    margin: 0 10px;
    background: #339dff;
    color: white;
    font-weight: lighter;
    border-color: #339dff;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    width: 100px;
  }

  .nameCol {
    color: #339dff;
    text-decoration: none;
    &:hover {
      color: #339dff;
      text-decoration: none;
    }
  }

  :global {
    .p-sortable-column-icon {
      &::before {
        color: #339dff;
        font-weight: 700;
        margin: 0 5px;
      }
    }
  
 
}


  }
}
.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
  width: 40px;
  height: 30px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #339dff;
  border-color: #eef2ff;
  color: white;
  width: 40px;
  height: 30px;
}
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  background-color: rgb(218, 206, 206);
  border: 0 none;
  color: #6c757d;
  min-width: 3rem;
  height: 40px;
  width: 30px;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}