:global {
    @media only screen and (max-width : 480px) {

        .Toastify__toast-container {
            left: 0;
            width: 100vw;
            margin: 0;
            padding: 0;
        }

        .Toastify__toast-container--top-left,
        .Toastify__toast-container--top-center,
        .Toastify__toast-container--top-right {
            top: 0;
            transform: translateX(0);
        }

        .Toastify__toast-container--bottom-left,
        .Toastify__toast-container--bottom-center,
        .Toastify__toast-container--bottom-right {
            bottom: 0;
            transform: translateX(0);
        }

        .Toastify__toast-container--rtl {
            right: 0;
            left: initial;
        }
    }

    @media only screen and (max-width : 480px) {

        .Toastify__toast {
            margin-bottom: 0;
            border-radius: 0;
        }
    }

    .Toastify__toast-container {
        position: fixed;
        z-index: 9999;
        transform: translate3d(0, 0, 9999px);
    }

    .Toastify__toast-container--top-left {
        top: 1em;
        left: 1em;
    }

    .Toastify__toast-container--top-center {
        top: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    .Toastify__toast-container--top-right {
        top: 68px;
        right: 12px;
    }

    .Toastify__toast-container--bottom-left {
        bottom: 1em;
        left: 1em;
    }

    .Toastify__toast-container--bottom-center {
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    .Toastify__toast-container--bottom-right {
        right: 1em;
        bottom: 1em;
    }

    .Toastify__toast--rtl {
        direction: rtl;
    }

    .Toastify__toast-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        margin: auto 0;
    }

    .Toastify--animate {
        animation-duration: .7s;
        animation-fill-mode: both;
    }

    .Toastify__close-button {
        align-self: flex-start;
        padding: 0;
        color: #fff;
        background: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        opacity: .7;
        transition: .3s ease;
        -ms-flex-item-align: start;
    }

    .Toastify__close-button--default {
        color: #000;
        opacity: .3;
    }

    .Toastify__close-button>svg {
        width: 14px;
        height: 16px;
        fill: currentColor;
    }

    .Toastify__close-button:hover,
    .Toastify__close-button:focus {
        opacity: 1;
    }

    .Toastify__progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        height: 5px;
        background-color: rgba(255, 255, 255, .7);
        transform-origin: left;
        opacity: .7;
    }

    .Toastify__progress-bar--animated :local {
        animation: Toastify__trackProgress linear 1 forwards;
    }

    .Toastify__progress-bar--controlled {
        transition: transform .2s;
    }

    .Toastify__progress-bar--rtl {
        right: 0;
        left: initial;
        transform-origin: right;
    }

    .Toastify__progress-bar--default {
        background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
    }

    .Toastify__progress-bar--dark {
        background: #bb86fc;
    }

    .Toastify__bounce-enter--top-left :local,
    .Toastify__bounce-enter--bottom-left :local {
        animation-name: Toastify__bounceInLeft;
    }

    .Toastify__bounce-enter--top-right :local,
    .Toastify__bounce-enter--bottom-right:local {
        animation-name: Toastify__bounceInRight;
    }

    .Toastify__bounce-enter--top-center :local {
        animation-name: Toastify__bounceInDown;
    }

    .Toastify__bounce-enter--bottom-center :local {
        animation-name: Toastify__bounceInUp;
    }

    .Toastify__bounce-exit--top-left :local,
    .Toastify__bounce-exit--bottom-left :local {
        animation-name: Toastify__bounceOutLeft;
    }

    .Toastify__bounce-exit--top-right :local,
    .Toastify__bounce-exit--bottom-right :local {
        animation-name: Toastify__bounceOutRight;
    }

    .Toastify__bounce-exit--top-center :local {
        animation-name: Toastify__bounceOutUp;
    }

    .Toastify__bounce-exit--bottom-center :local {
        animation-name: Toastify__bounceOutDown;
    }

    .Toastify__zoom-enter :local {
        animation-name: Toastify__zoomIn;
    }

    .Toastify__zoom-exit :local {
        animation-name: Toastify__zoomOut;
    }

    .Toastify__flip-enter :local {
        animation-name: Toastify__flipIn;
    }

    .Toastify__flip-exit :local {
        animation-name: Toastify__flipOut;
    }

    .Toastify__slide-enter--top-left :local,
    .Toastify__slide-enter--bottom-left :local {
        animation-name: Toastify__slideInLeft;
    }

    .Toastify__slide-enter--top-right :local,
    .Toastify__slide-enter--bottom-right :local {
        animation-name: Toastify__slideInRight;
    }

    .Toastify__slide-enter--top-center :local {
        animation-name: Toastify__slideInDown;
    }

    .Toastify__slide-enter--bottom-center :local {
        animation-name: Toastify__slideInUp;
    }

    .Toastify__slide-exit--top-left :local,
    .Toastify__slide-exit--bottom-left :local {
        animation-name: Toastify__slideOutLeft;
    }

    .Toastify__slide-exit--top-right :local,
    .Toastify__slide-exit--bottom-right :local {
        animation-name: Toastify__slideOutRight;
    }

    .Toastify__slide-exit--top-center :local {
        animation-name: Toastify__slideOutUp;
    }

    .Toastify__slide-exit--bottom-center :local {
        animation-name: Toastify__slideOutDown;
    }
}

@keyframes Toastify__trackProgress {

    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

@keyframes Toastify__bounceInRight {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    from {
        transform: translate3d(3000px, 0, 0);
        opacity: 0;
    }

    60% {
        transform: translate3d(-25px, 0, 0);
        opacity: 1;
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutRight {

    20% {
        transform: translate3d(-20px, 0, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(2000px, 0, 0);
        opacity: 0;
    }
}

@keyframes Toastify__bounceInLeft {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        transform: translate3d(-3000px, 0, 0);
        opacity: 0;
    }

    60% {
        transform: translate3d(25px, 0, 0);
        opacity: 1;
    }

    75% {
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        transform: translate3d(5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutLeft {

    20% {
        transform: translate3d(20px, 0, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(-2000px, 0, 0);
        opacity: 0;
    }
}

@keyframes Toastify__bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    from {
        transform: translate3d(0, 3000px, 0);
        opacity: 0;
    }

    60% {
        transform: translate3d(0, -20px, 0);
        opacity: 1;
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__bounceOutUp {

    20% {
        transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
        transform: translate3d(0, 20px, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(0, -2000px, 0);
        opacity: 0;
    }
}

@keyframes Toastify__bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        transform: translate3d(0, -3000px, 0);
        opacity: 0;
    }

    60% {
        transform: translate3d(0, 25px, 0);
        opacity: 1;
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutDown {

    20% {
        transform: translate3d(0, 10px, 0);
    }

    40%,
    45% {
        transform: translate3d(0, -20px, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(0, 2000px, 0);
        opacity: 0;
    }
}

@keyframes Toastify__zoomIn {

    from {
        transform: scale3d(.3, .3, .3);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes Toastify__zoomOut {

    from {
        opacity: 1;
    }

    50% {
        transform: scale3d(.3, .3, .3);
        opacity: 0;
    }

    to {
        opacity: 0;
    }
}

@keyframes Toastify__flipIn {

    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
        animation-timing-function: ease-in;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}

@keyframes Toastify__flipOut {

    from {
        transform: perspective(400px);
    }

    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@keyframes Toastify__slideInRight {

    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInLeft {

    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInUp {

    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInDown {

    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideOutRight {

    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(110%, 0, 0);
        visibility: hidden;
    }
}

@keyframes Toastify__slideOutLeft {

    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(-110%, 0, 0);
        visibility: hidden;
    }
}

@keyframes Toastify__slideOutDown {

    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 500px, 0);
        visibility: hidden;
    }
}

@keyframes Toastify__slideOutUp {

    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, -500px, 0);
        visibility: hidden;
    }
}