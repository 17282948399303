.overviewSection {
  margin-bottom: 20px;
  width: 100%;
  background: #f5f9fd;

  .sectionTitle {
    color: #3e74a7;
    font-weight: 300;
    margin: 10px 0;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
  }

  .cardItem {
    width: 32%;
    margin: 0 7px;
  }
  @media only screen and (max-width: 600px) {
    .cardItem {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}

.taxpayersSection {
  margin: 70px 0;
  .sectionTitle {
    color: #3e74a7;
    font-weight: 300;
    margin: 10px 0;
  }
}
