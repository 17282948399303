.sidebar_container {
    display: flex;
    padding: 0% !important;
}

.sidebar_tabs {
    background: linear-gradient(0deg, #3F77AC 0%, #35526E 100%) 0% 0% no-repeat padding-box;
    width: 15%;
}

.sidebar_inner {
    margin-top: 20px;
}

.sidebar_children {
    width: 85%;
    padding: 20px;
}

.sidebar_item {
    display: flex;
    height: 42px;
    padding: 0.5rem;
    color: #fff;
    opacity: 0.5;
    cursor: pointer;
}

.tab_selected {
    opacity: 1;
    color: #fff;
    background-color: #546d86;
    border-left: solid 5px white;
    cursor: default;
}