.section_title {
    color: #3e74a7;
    font-weight: 300;
    margin: 10px 0;
  }
  button {
    width: 150px;
    height: 40px;
    padding: 10px 20px;
    font-weight: lighter;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #0056b3;
    }
    
  }
  
  