.container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  //   width: 100%;
  padding: 10px;
  height: 133px;
  //   width: 370px;

  .title {
    color: #43425d;
    margin: 0;
  }
  .value {
    font-weight: bold;
    margin: 0;
  }
  .dataContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: self-start;
    width: 50%;
  }

  .icon {
    padding: 5px;
    object-fit: contain;
    width: 82px;
    height: 83px;
    margin: auto 0;
  }
}
