.calendarContainer {
    display: flex;
    
}

.calendarInner {
    height: 34px;
    width: 150px;

    &>.p-button {
        background: #339dff !important;
        border: 1px solid #339dff !important;
        height: 34px;
        width: 50px;
      }
}