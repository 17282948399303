.searchContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  // margin-bottom: 30px;

  p {
    margin: 0 20px 0 0;
  }
  input {
    padding: 4px 10px;
    border-color: #339dff;
    font-size: 14px;
    box-shadow: none;
    width: 300px;
    &:hover {
      border-color: #339dff;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  i::before {
    color: #339dff;
    font-weight: bolder;
  }
  .resetBtn {
    border: none;
    height: 40px;
    font-size: 1.00rem;
    margin: 0 10px;
    background: #cf4141;
    color: white;
    font-weight: lighter;
    border-color: #cf4141;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    width: 100px;
  }
  .searchBtn {
    border: none;
    height: 40px;
    font-size: 0.85rem;
    margin: 0 10px;
    background: #339dff;
    color: white;
    font-weight: lighter;
    border-color: #339dff;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    width: 100px;
  }
}
