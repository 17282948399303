.header-container {
  display: flex;
  padding: 10px 0;
  height: 100%;
  justify-content: space-between;
  background: transparent linear-gradient(0deg, #3f77ac 0%, #35526e 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  .title {
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin: auto;
  }
  .profileUser {
    display: flex;
    align-items: center;
    border-left: 1px solid white;
    h6 {
      color: white;
    }
    .profileImg {
      width: 50px;
      border-radius: 50%;
    }
    .dropdown {
      button {
        background: transparent;
        color: white;
        border: none;
        box-shadow: none;
        outline: none;
      }
      ul {
        margin: 0;
        position: fixed;
        background: white;
        width: 100%;
        padding: 4px 10px;
        margin-top: 13px;
        background: #d2d2d2;
        width: 80%;
        width: 130px;
        li {
          cursor: pointer;
        }
      }
    }
    .p-dropdown {
      background: transparent;
      border-color: transparent;
      box-shadow: none;
      outline: none;
      &:hover {
        border-color: transparent;
      }
      .p-dropdown-label {
        width: 0;
        padding: 0;
      }
      .p-dropdown-trigger {
        width: 2rem;
        color: white;
      }
    }

    .p-dropdown-items {
      padding: 0;
    }
  }
}
