:global {
  :root {
    --color-dodger-blue: #339dff;
    --color-theme-dark-blue: #35526e;
    --color-theme-light-blue: #3f77ac;
    --color-theme-white: #f5f9fd;
    --color-jagged-ice: #c7deec;
    --color-concrete: #f2f2f2;
    --color-mercury: #e2e2e2;
    --color-white: #fff;
    --color-black: #2e3045;
    --color-tamarillo: #a11d18;
    --color-silver: #cccccc;
    --color-aqua-spring: #f8fbfd;
    --color-shuttle-gray: #61656e;
    --color-silver-chalice: #abaaaa;
    --color-red: #ff0101;
  }
}
