.loader-overlay {
  opacity: 0.5;
  filter: alpha(opacity=20);
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  position: fixed;
}
