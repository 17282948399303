/* Modal.styles.scss */

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &.open {
    display: flex;
  }

  .modal-content {
    background-color: #fff;
    width: 300px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .modal-header {
    background-color: #339dff; /* Change the title background color to #339dff */
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 18px;
      font-family: "Verdana", sans-serif;
    }

    .modal-close {
      background: none;
      border: none;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      padding: 0;
      
    }
  }

  .modal-body {
    padding: 20px;
  }

  .modal-footer {
    padding: 10px;
    text-align: right;

    .modal-confirm {
      background-color: #339dff; /* Change the confirm button background color to #339dff */
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #4a833c;
      }
    }
  }
}
