.layout {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-theme-white);
  .line {
    width: 100%;
    height: 70px;
    background-image: linear-gradient(
      0deg,
      var(--color-theme-dark-blue),
      var(--color-theme-light-blue)
    );
  }

  .inner {
    position: relative;
    flex: 1;
    background: #f5f9fd;
    padding: 20px 150px;
    min-height: 100vh;
  }
  @media only screen and (max-width: 600px) {
    .inner {
      padding: 20px;
    }
  }
}
