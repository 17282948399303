/* ChangePasswordForm.css */
/* ChangePasswordForm.css */
.form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 40px;
    border-radius: 4px;
    background-color: #282c34;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    text-align: center;
    color: white;
}

/* Add an overlay for the content behind the box */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Place the overlay behind the form */
}


.form-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.form-container input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff; /* Use white background for input fields */
    color: #282c34; /* Text color for input fields */
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button-container button {
    background-color: #61dafb; /* Match the color of App-link */
    color: #282c34; /* Match the text color of App-header */
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.button-container button:hover {
    background-color: #007bff;
}
