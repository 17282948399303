/* DateFilter.styles.scss */
.calendarContainer {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.calendarInner {
  width: 300px; /* Adjust the width as needed to make it bigger */
  margin: 0 5px;
}

/* YearMonthFilter.styles.scss */
/* You can use similar styles for the YearMonthFilter */
.calendarContainer {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.calendarInner {
  width: 250px; /* Adjust the width as needed to make it bigger */
  margin: 0 5px;
}
