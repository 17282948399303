.devices_container {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000a;
    opacity: 1;
    padding: 40px;

    .searchContainer {
        display: flex;
        align-items: center;
        // margin-bottom: 30px;

        p {
            margin: 0 20px;
        }

        input {
            padding: 4px 10px;
            border-color: #339dff;
            font-size: 14px;
            box-shadow: none;
            width: 300px;

            &:hover {
                border-color: #339dff;
            }

            &:focus {
                box-shadow: none !important;
            }
        }

        i::before {
            color: #339dff;
            font-weight: bolder;
        }
    }

    .searchBtn {
        border: none;
        height: 40px;
        font-size: 1.00rem;
        margin: 0 10px;
        background: #339dff;
        color: white;
        font-weight: lighter;
        border-color: #339dff;
        box-shadow: none;
        outline: none;
        border-radius: 5px;
        width: 100px;
    }

    .nameCol {
        color: #339dff;
        text-decoration: none;

        &:hover {
            color: #339dff;
            text-decoration: none;
        }
    }
}

:global {
    .p-sortable-column-icon {
        &::before {
            color: #339dff;
            font-weight: 700;
            margin: 0 5px;
        }
    }

    
}

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover,
.p-togglebutton.p-button.p-highlight:focus,
.p-togglebutton.p-button,
.p-togglebutton.p-button:hover,
.p-togglebutton.p-button:focus,
.p-togglebutton.p-button.p-highlight,
.p-button:focus,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background-color: transparent;
    border: none;
    background: transparent;
    box-shadow:none
}


.pi-enabled {
    content: url('../../../../assets/img/enable.svg');
}

.pi-disabled {
    content: url('../../../../assets/img/disable.svg')
}
.modal-input {
    border: 1px solid #ccc; /* You can adjust the border color and width as needed */
    padding: 5px;
    width: 100%;
    border-radius: 4px;
  }




